import React, { useState } from "react";
import Modal from "react-modal";

function Opinion() {
  const [opinionModalIsOpen, setOpinionModalIsOpen] = useState(false);

  return (
    <div className="Opinion">
      <div>
        <div className="opinion"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpinionModalIsOpen(true);
          }}>
          📨ご意見・ご感想
        </div>
      </div>

      <Modal isOpen={opinionModalIsOpen}>
        <div className="opinion_modal">
          <h2>ご意見・ご感想</h2>

          <form name="opinion_form2" action="https://maker.ifttt.com/trigger/nabe_opinion/with/key/d-7GZwh8XvocozIqLM_xBg" target="_blank" method="post">
            <p>
              鍋のことから、サイトの不具合、要望まで、何でもお気軽にご意見・ご感想をお寄せください。
            </p>
            <p>
              <label htmlFor="name">お名前
              </label>
              <input type="text" required name="value1" id="name" />
            </p>
            <p>
              <label>
                タイトル
                <input type="text" required name="value2" />
              </label>
            </p>
            <p>
              <label>
                ご意見・ご感想
                <textarea required name="value3" />
              </label>
            </p>
            <p className="register_btn">
              <input type="submit" value="送信" />
            </p>
          </form>
        </div>
        <div className="close" onClick={() => setOpinionModalIsOpen(false)}>
          閉じる
        </div>
      </Modal>
    </div>
  );
}

export default Opinion;
