import React from 'react';
import liveBanner from './img/nabelive.png';

function Links() {
  return (
    <div className="Links">
      <div>
        <p>
          <a href="soudan.php" target="_blank" rel="noreferrer">食材相談掲示板</a>
        </p>
        <p>
          <a href="waitingboard.php" target="_blank" rel="noreferrer">待ち合わせ掲示板</a>
        </p>
        <p>
          <a href="photo.php" target="_blank" rel="noreferrer">写真アップロード</a>
        </p>
        <p>
          <a href="https://docs.google.com/spreadsheets/d/14NQJfQUGFe1kaiyQtNxhXQgf5WCYfLO2fJuKzLN8NnI/edit?usp=sharing" target="_blank" rel="noreferrer">鍋名簿</a>
        </p>
        <p className="streaming">
          配信中
        </p>
        <p className='streaming'>
          話しかけれます。
        </p>
        <p>
          <a href="./video.php?url=nabe" className="banners">
            <img src={liveBanner} alt="nabelive" srcSet="" width="80%" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default Links;
