import React from "react";
import Modal from "react-modal";
import Header from "./Header";
import Introduction from "./Introduction";
import Form from "./Form";
import Links from "./Links";
import Schedule from "./Schedule";
import Opinion from "./Opinion";

function App() {
  Modal.setAppElement(document.getElementById('root'))
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <div className="App-body">
        <Introduction />
        <Form />
        <Schedule />
        <Links />
        <Opinion />
      </div>
      <div className="App-footer">
      </div>
    </div>
  );
}

export default App;
