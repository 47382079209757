import { useState } from "react";
import Modal from "react-modal";
import Date221028 from "./schedules/Date221028";
import Date221103 from "./schedules/Date221103";
import Date221111 from "./schedules/Date221111";
import Date221216 from "./schedules/Date221216";
import Date221224 from "./schedules/Date221224";
import Date230210 from "./schedules/Date230210";

function Schedule() {
    const [date221028ModalIsOpen, set221028ModalIsOpen] = useState(false);
    const [date221103ModalIsOpen, set221103ModalIsOpen] = useState(false);
    const [date221111ModalIsOpen, set221111ModalIsOpen] = useState(false);
    const [date221216ModalIsOpen, set221216ModalIsOpen] = useState(false);
    const [date221224ModalIsOpen, set221224ModalIsOpen] = useState(false);
    const [date230210ModalIsOpen, set230210ModalIsOpen] = useState(false);


    return (
        <div className="Schedule">
            <div>
                <h2>開催予定</h2>
                <div className="date230210 scheduled_date" onClick={() => set230210ModalIsOpen(true)}>
                    2月10日(金)
                </div>

            </div>
            <div id="passed">
                <h2>終わったもの</h2>
                <div className="date221009 scheduled_date" >
                    10月9日(日)
                </div>
                <div className="date221028 scheduled_date">
                    10月28日(金)
                </div>
                <div className="date221103 scheduled_date">
                    11月3日(木)
                </div>
                <div className="date221111 scheduled_date">
                    11月11日(金)
                </div>
                <div className="date221216 scheduled_date">
                    12月16日(金)
                </div>
                <div className="date221224 scheduled_date">
                    12月24日(土)
                </div>
            </div>

            <div id="mask" className="hidden"></div>
            {/* 221028 */}
            <Modal isOpen={date221028ModalIsOpen}>
                <div>
                    <Date221028 />
                </div>
                <div className="close" onClick={() => set221028ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            {/* 221103 */}
            <Modal isOpen={date221103ModalIsOpen}>
                <div>
                    <Date221103 />
                </div>
                <div className="close" onClick={() => set221103ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            {/* 221111 */}
            <Modal isOpen={date221111ModalIsOpen}>
                <div>
                    <Date221111 />
                </div>
                <div className="close" onClick={() => set221111ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date221216ModalIsOpen}>
                <div>
                    <Date221216 />
                </div>
                <div className="close" onClick={() => set221216ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date221224ModalIsOpen}>
                <div>
                    <Date221224 />
                </div>
                <div className="close" onClick={() => set221224ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date230210ModalIsOpen}>
                <div>
                    <Date230210 />
                </div>
                <div className="close" onClick={() => set230210ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
        </div>
    );

}

export default Schedule;
