import ReactMarkdown from "react-markdown";

const markdownText = `
## 11月3日(木)の予定
- 場所: [西武池袋線 椎名町駅徒歩5分](https://goo.gl/maps/QV5zKzCNpAw84tao9)
- 日時: 11月3日(木)8時〜20時
- 費用: 好きな食材（と酒）
- 連絡先: [電話](080-4540-7479)
- 備考: 好きな時に来て好きな時に帰れます
### タイムテーブル
- 8時〜10時: 仕込み
- 10時〜13時: 昼食
- 13時〜16時: おやつ
- 16時〜19時: 夕食
- 19時〜20時: グランドフィナーレ
`

function Date221103() {
    return <ReactMarkdown>{markdownText}</ReactMarkdown>;

}

export default Date221103;
