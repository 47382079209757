import ReactMarkdown from "react-markdown";

const markdownText = `
## 2022年12月24日(土)の予定
- 場所: [西武池袋線椎名町](https://goo.gl/maps/QV5zKzCNpAw84tao9)
- 日時: 19:00~
- 費用: 酒や食べ物
- 連絡先: 080-4540-7479
- 備考：-
`

function Date221224() {
    return <ReactMarkdown>{markdownText}</ReactMarkdown>;

}

export default Date221224;
