import ReactMarkdown from "react-markdown";

const markdownText = `
## 10月28日(金)
- 場所: [カラオケ.net](https://カラオケ.net/) V.S. [西武池袋線椎名町](https://goo.gl/maps/QV5zKzCNpAw84tao9)
- 日時: 19:00~
- 費用: 酒や食べ物
- 連絡先: 080-4540-7479
- 備考：[鍵](https://z5zyj.app.goo.gl/HbogGqKALRSV3w2q7)
`

function Date221028() {
    return <ReactMarkdown>{markdownText}</ReactMarkdown>;

}

export default Date221028;
