function Introduction() {
    return (
        <div className="Introduction">
            <div id="description" className="">
                <p>鍋.netは、鍋を食べたい人のためのサイトです。</p>
                <p>鍋を食べたい人は、鍋.netに登録してください。</p>
                <p>鍋.netに登録すると、鍋を食べることができます。</p>
            </div>
        </div>
    );

}

export default Introduction;
