function Form() {
    return (
        <div className="Form">
            <form name="register_form" action="https://maker.ifttt.com/trigger/nabetouroku/with/key/d-7GZwh8XvocozIqLM_xBg" target="_blank" method="post">
                <p>
                    <label htmlFor="name">名前(公開されます)
                    </label>
                    <input type="text" name="value1" id="name" />
                </p>
                <p>
                    <label>
                        なに鍋？
                        <input type="text" name="value2" />
                    </label>
                </p>
                <p>
                    <label>
                        いつ鍋？
                        <input type="datetime-local" name="value3" />
                    </label>
                </p>
                <p className="register_btn">
                    <input type="submit" value="🍲登録🍲" />
                </p>
            </form>
        </div>
    );

}

export default Form;
